
import { getModuleType, getReviewResult, AUDIT_RESULTS_CODE, STATUS, AUDIT_RESULTS } from '@/views/deploy/index';
import { defineComponent, reactive, ref, toRefs, onBeforeMount, onMounted } from 'vue';
import ListWrap from '@/components/list-wrap/Index.vue';
import PackagedPagination from '@/components/pagination/Index.vue';
import VersionInfoDialog from '@/views/service-repository/detail/Version-Info-Dialog.vue';
import { ElMessage } from 'element-plus';
import { reviewApply, getReviewList } from '@/api/deploy/deploy-review';
import { findPublisherByName, getSnapshotNo } from '@/api/deploy/deploy-apply';
import dateFormat from '@/utils/date-format';
import { getShowBool } from '@/utils/permission-show-module';
import { userInfo } from '@/layout/messageCenter/user-info';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { useSaList } from '@/shared/hooks/list';

interface TableState {
  reviewDialogVisible: boolean;
  publisherFilters: Array<object>;
  reviewerFilters: Array<object>;
  auditResultsFilters: Array<object>;
}

interface RiewFormState {
  isEdit: boolean;
  disabled: boolean;
  id: string;
  formData: {
    reviewer: number;
    reviewContent: string;
    type: number;
  };
}

export default defineComponent({
  components: { ListWrap, PackagedPagination, VersionInfoDialog, ServiceName },
  setup() {
    const btnLoading = ref(false);
    const tableState: TableState = reactive({
      statusFilters: [],
      auditResultsFilters: [],
      publisherFilters: [],
      reviewerFilters: [],
      reviewDialogVisible: false,
    });
    const extendQuery = reactive({
      status: null,
      auditResults: null,
      sortField: 'applyTime',
      sortType: 'descending',
    });

    const reviewState: RiewFormState = reactive({
      isEdit: false,
      disabled: false,
      id: '',
      formData: {
        // reviewer: 0,
        reviewer: userInfo.value.userId,
        reviewContent: '',
        type: 1,
      },
    });

    function initReviewState() {
      reviewState.isEdit = false;
      reviewState.disabled = false;
      reviewState.id = '';
      reviewState.formData = {
        reviewer: userInfo.value.userId,
        reviewContent: '',
        type: 1,
      };
    }

    const versionInfoDialogRef = ref(null as any);

    const { loading, list, total, query, fetchList, handleSearch, handlePageSizeChange, handlePageChange } = useSaList(
      async (query: any) => {
        try {
          loading.value = true;
          const { data } = await getReviewList({
            ...query,
            ...extendQuery,
          });
          const { count, rows = [] } = data;
          total.value = count;
          list.value = rows.map((item: any, index: number) => ({
            ...item,
            moduleType: getModuleType(item.type),
            reviewResult: getReviewResult(item.status),
            index: index + 1,
          }));
          tableState.auditResultsFilters = Object.entries(STATUS).map((item) => ({
            id: item[0],
            name: item[1] ? item[1] : '未审核',
          }));
        } catch (error) {
          ElMessage({
            type: 'error',
            message: '获取申请列表失败',
          });
        }
        loading.value = false;
      },
    );

    fetchList();

    const isReviewed = (row: any) => row.reviewResult;

    const reviewForm = ref(null as any);
    const reviewRules = [
      { required: true, message: '审核内容不能为空', trigger: 'blur' },
      { min: 1, max: 2048, message: '长度在 1 到 2048 个字符' },
    ];

    const openReviewDialog = () => {
      tableState.reviewDialogVisible = !tableState.reviewDialogVisible;
    };

    const closeReviewDialog = () => {
      reviewForm.value.resetFields();
      initReviewState();
      openReviewDialog();
    };

    const blackHoverVisible = ref(false);
    const publisherTitleVisiable = ref(false);
    const isShowPopover = ref(true);
    function publisherTitleClick() {
      publisherTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function publisherChange() {
      publisherTitleVisiable.value = false;
      isShowPopover.value = false;
      blackHoverVisible.value = false;
      await fetchList();
      isShowPopover.value = true;
    }

    const reviewResultsTitleVisiable = ref(false);
    function reviewResultsTitleClick() {
      reviewResultsTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function auditResultsChange() {
      reviewResultsTitleVisiable.value = false;
      blackHoverVisible.value = false;
      await fetchList();
    }

    const reviewerTitleVisiable = ref(false);
    function reviewerTitleClick() {
      reviewerTitleVisiable.value = true;
      blackHoverVisible.value = true;
    }

    async function reviewerChange() {
      reviewerTitleVisiable.value = false;
      isShowPopover.value = false;
      blackHoverVisible.value = false;
      await fetchList();
      isShowPopover.value = true;
    }

    function blackHoverclick() {
      reviewResultsTitleVisiable.value = false;
      reviewerTitleVisiable.value = false;
      publisherTitleVisiable.value = false;
      blackHoverVisible.value = false;
    }

    function getNameByCode(code: number, type: string): string {
      let name = '';
      switch (type) {
        case 'status':
          name = STATUS[code];
          break;
        case 'auditResults':
          name = AUDIT_RESULTS[code];
          break;
        default:
          break;
      }
      return name;
    }

    const onReview = async (rowData: any) => {
      reviewState.isEdit = true;
      reviewState.disabled = true;
      reviewState.id = rowData.id;
      reviewState.formData = {
        ...reviewState.formData,
        ...rowData,
      };
      openReviewDialog();
    };

    async function submitReviewForm(tempStatus: boolean) {
      reviewForm.value.validate(async (valid: boolean) => {
        if (valid) {
          btnLoading.value = true;
          const reviewData = {
            id: reviewState.id,
            status: tempStatus ? AUDIT_RESULTS_CODE.PASSED : AUDIT_RESULTS_CODE.FAILED,
            reviewContent: reviewState.formData.reviewContent,
          };
          try {
            const { code } = await reviewApply(reviewData);
            if (code === 0) {
              ElMessage({
                type: 'success',
                message: '审核成功',
              });
              fetchList();
            } else {
              ElMessage({
                type: 'error',
                message: '审核失败',
              });
            }
            btnLoading.value = false;
            closeReviewDialog();
          } catch (e) {
            ElMessage({
              type: 'error',
              message: '审核失败',
            });
            btnLoading.value = false;
          }
        }
      });
    }

    const handleShowVersionInfo = async (row: any) => {
      const { data } = await getSnapshotNo(row.id);
      versionInfoDialogRef.value.handleOpen(data);
    };

    onBeforeMount(() => {
      blackHoverclick();
    });

    onMounted(async () => {
      const { data = [] } = await findPublisherByName();
      const users = data?.users;
      const publishers = data?.publishers;
      const reviewers = data?.reviewers;
      const userFilters = users.map((item: any) => ({
        id: item.id,
        name: item.displayName,
      }));
      tableState.publisherFilters = userFilters.filter((item: any) => publishers.includes(item.id));
      tableState.reviewerFilters = userFilters.filter((item: any) => reviewers.includes(item.id));
    });

    return {
      ...toRefs(tableState),
      loading,
      list,
      total,
      query,
      extendQuery,
      fetchList,
      handleSearch,
      handlePageSizeChange,
      handlePageChange,
      reviewState,
      dateFormat,
      getShowBool,
      isReviewed,
      onReview,
      reviewRules,
      closeReviewDialog,
      getNameByCode,
      reviewForm,
      submitReviewForm,
      blackHoverVisible,
      publisherTitleVisiable,
      publisherTitleClick,
      publisherChange,
      reviewResultsTitleVisiable,
      reviewResultsTitleClick,
      auditResultsChange,
      reviewerTitleVisiable,
      reviewerTitleClick,
      reviewerChange,
      blackHoverclick,
      isShowPopover,
      versionInfoDialogRef,
      handleShowVersionInfo,
      btnLoading,
    };
  },
});
